import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/SideBar";
import { Box, useMediaQuery, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import sidebarLogo from "../../assets/images/logo/logoIcon.svg";
import withHighlight from "../../components/GlobalSearch/withHighlight";
import Loader from "../../components/Loader";
const drawerWidth = 240;
const closedDrawerWidth = 56;

function MainLayout({ highlightSearch }) {
  const [open, setOpen] = useState(true); // Sidebar is open by default
  const handleDrawerToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const { user_detail: UserDetail } = useSelector(
    (state) => state.root.login?.user,
  );
  const { commanHeader } = useSelector((state) => state.root.dashBoardState);
  const loading = useSelector((state) => state.root.loading?.loading);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <>
      <Navbar handleDrawerToggle={handleDrawerToggle} open={open} />
      <Sidebar open={open} handleDrawerToggle={handleDrawerToggle} />
      {/* {loading && (
        <div className="loader-overlay">
          <div className="spinner" style={{ width: '80px', height: '80px' }}>
            <img src={sidebarLogo} className="loaderLogo" style={{
              top: '35%', left: '38%',
              transform: 'translate(-35%, -35%)', width: '25px', height: '25px'
            }} />
          </div>
        </div>
      )} */}
      <Loader />
      <Box
        component="main"
        className="main-layout"
        sx={{
          flexGrow: 1,
          width: `calc(100% - ${!isSmallScreen ? (open ? drawerWidth : closedDrawerWidth) : 0}px)`,
          marginLeft: `${!isSmallScreen ? (open ? drawerWidth : closedDrawerWidth) : 0}px`,
          transition: "margin 0.3s ease, width 0.3s ease",
        }}
      >
        {/* Comman header */}
        {commanHeader === "dashboard" && (
          <>
            <Typography textAlign={"start"} variant="headingXXS" component="h4">
              {" "}
              {highlightSearch(
                `Welcome, ${UserDetail?.first_name} ${UserDetail?.last_name}`,
              )}
            </Typography>
            <Typography
              textAlign={"start"}
              variant="bodyXS"
              component="p"
              sx={{ m: 0, mb: 4 }}
            >
              {" "}
              {highlightSearch(`Here’s what’s going on today`)}
            </Typography>
          </>
        )}
        {commanHeader === "Settings" && (
          <>
            <Typography
              textAlign={"start"}
              variant="headingXXS"
              component="h4"
              sx={{ m: 0, mb: 0 }}
            >
              {highlightSearch(commanHeader)}
            </Typography>
          </>
        )}
        <Outlet />
      </Box>
    </>
  );
}

export default withHighlight(MainLayout);
