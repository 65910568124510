import React, { useState } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { validateConfirmEmailSubmit } from "../../../utils/Validation";
import { useDispatch } from "react-redux";
import TextFieldComponent from "../../../components/TextFieldComponent";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/logo/logo.svg";
import { confirmEmailAddress } from "../../../redux/thunk/auth/authThunk";

const ConfirmEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [formSubmit, setFormSubmit] = useState({
    email: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormSubmit({
      ...formSubmit,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "", // Clear the error for the current field
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateConfirmEmailSubmit(formSubmit);
    if (!isValid) {
      setErrors(errors);
    } else {
      let req = {
        apiData: formSubmit,
        navigate: navigate,
      };
      dispatch(confirmEmailAddress(req));
    }
  };

  return (
    <>
      <div className="authPage SignIn">
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6} md={6}>
            <div className="authLeft">
              <div className="authTopContent">
                <div className="inner">
                  <Typography
                    variant="headingS"
                    className="text-primary"
                    component="h2"
                    sx={{ letterSpacing: "normal", mb: 0 }}
                  >
                    Save your time and money
                  </Typography>
                  <Typography
                    className="text-dark"
                    component="p"
                    sx={{ fontSize: "18px", mb: 0 }}
                  >
                    As experienced sellers ourselves, we know exactly what works
                    and what doesn’t. You only pay if we’re successful.
                  </Typography>
                </div>
              </div>
              <div className="authBottomContent"></div>
              <div className="authLogo">
                <img src={Logo} alt="Logo" />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} sm={8}>
                <div
                  className="authForm"
                  style={{
                    display: "flex",
                    padding: "0px 16px",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    gap: "16px",
                  }}
                >
                  <Box
                    className="logo"
                    sx={{ display: { sm: "block", md: "none" } }}
                  >
                    <img src={Logo} alt="Logo" />
                  </Box>
                  <div>
                    <Typography
                      variant="headingXS"
                      component="h3"
                      sx={{ letterSpacing: "normal" }}
                    >
                      Reset Password In to RefundStacker
                    </Typography>
                    <Typography
                      variant="bodyM"
                      component="p"
                      sx={{ letterSpacing: "normal", mb: 2 }}
                    >
                      Generate New Password Here{" "}
                    </Typography>
                  </div>
                  <TextFieldComponent
                    id="email"
                    name="email"
                    placeholder="Enter register email address"
                    value={formSubmit?.email}
                    label="Email"
                    type="email"
                    onChange={handleInputChange}
                    error={errors.email}
                  />
                  <Button
                    sx={{ mt: 2 }}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ConfirmEmail;
