import { toast } from "sonner";

// sucess toaster
export const SuccessToast = (message) => {
  return toast.success(message, {
    duration: 3000,
  });
};
export const InfoToast = (message) => {
  return toast.info(message, {
    duration: 3000,
  });
};

// error toaster

export const ErrorToast = (errors) => {
  const errorMessages = [];

  // If errors is an array of error messages
  if (Array.isArray(errors)) {
    errors.forEach((error) => {
      if (typeof error === "string") {
        errorMessages.push(error);
      } else if (error.message) {
        errorMessages.push(error.message);
      }
    });
  }
  // If errors is an object with specific properties
  else if (typeof errors === "object") {
    // Check for known properties like 'email' or others
    Object.keys(errors).forEach((key) => {
      const messages = errors[key];
      if (Array.isArray(messages)) {
        messages.forEach((message) => {
          errorMessages.push(message);
        });
      } else if (typeof messages === "string") {
        errorMessages.push(messages);
      }
    });
  }
  // If errors is a single error object
  else if (errors.error || errors.message) {
    errorMessages.push(errors.error || errors.message);
  }

  // Display all collected error messages
  if (errorMessages.length > 0) {
    toast.error(errorMessages.join(" "), {
      duration: 3000,
    });
  }
};
