import { useSelector } from "react-redux";
import "./App.css";
import { ToasterProvider } from "./provider/Toaster_provider";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import themes from "./themes";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import "./assets/scss/styles.scss";
import Rounting from "./layout/routing";

function App() {
  // const themes = useTheme();
  const customization = useSelector((state) => state.customization);
  const stripePromise = loadStripe(
    "pk_test_51Pt16x095AwtTyowhmYF5s54P4dmQTIt6zEp8jaYXK83i39RrQdVXijvzuc9urEdpM9KzFseP61AWAbavAaWdYsB00ZYTZy9vw",
  );
  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <ToasterProvider />
          <Elements stripe={stripePromise}>
            <Rounting />
          </Elements>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}

export default App;
