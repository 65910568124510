import { BorderBottom } from "@mui/icons-material";

export default function themeOverrides(theme) {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: theme.colors.white,
          boxShadow: "none",
          padding: "0px 0px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          padding: "12px 16px",
          backgroundColor: theme.colors.lightPrimary,
          borderRadius: theme.colors.themeRadius,
          "& .MuiInputBase-root": {
            color: theme.colors.textDark,
            borderRadius: "0",
            padding: "0px",

            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "0!important",
            },
            "& input::placeholder": {
              color: theme.colors.textDark,
              opacity: 0.6,
              textTransform: "capitalize",
            },
          },
          height: "auto!important",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            color: theme.colors.textDark,
            "& .MuiSelect-select": {
              padding: "12px 16px",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.colors.primary,
            },
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px",
              },
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.colors.textSecondary,
          fontSize: "12px",
          marginBottom: "8px",
          "&.MuiInputLabel-shrink": {
            transform: "none",
            textTransform: "capitalize",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.topbarSearch": {
            borderRadius: theme.colors.themeRadius,
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.colors.lightGray,
            padding: "12px 16px",
            color: theme.colors.textSecondary,
            marginLeft: "32px",
            backgroundColor: theme.colors.white,
          },
          "& .MuiInputBase-input": {
            padding: "0",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "12px",
          fontWeight: 600,
          borderRadius: theme.colors.themeRadius,
          boxShadow: theme.colors.themeBoxShadow,
          textTransform: "capitalize",
          lineHeight: "1.2",
          "&:hover": {
            boxShadow: theme.colors.themeBoxShadow,
          },
          "&.MuiButton-outlined": {
            borderColor: "#EFF2F5",
            color: theme.colors.textSecondary,
          },
          "&.MuiButton-outlinedError": {
            borderColor: "#9D1444",
            color: "#9D1444",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          padding: "4px 12px",
          borderRadius: theme.colors.themeRadius,
          fotnSize: "12px",
          fontWeight: "600",
          "& .MuiChip-label": {
            padding: "0px",
          },
          "&.MuiChip-colorSuccess": {
            backgroundColor: "#D4F7DF",
            color: "#50CD89",
          },
          "&.MuiChip-colorPrimary": {
            backgroundColor: "#D1ECFF",
            color: "#0787FF",
          },
          "&.MuiChip-colorWarning": {
            backgroundColor: "#FFF9C5",
            color: "#F89C47",
          },
          "&.MuiChip-colorError": {
            backgroundColor: "#FCE7F2",
            color: "#9D1444",
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: "0px!important",
          paddingRight: "0px!important",
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          color: theme.colors.textDark,
          fontSize: "12px",
          fontWeight: "600",
          borderWidth: "1px 0px 1px 0px",
          borderStyle: "solid",
          borderColor: theme.colors.lightGray,
          padding: "12px 16px",
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          padding: "0px 16px!important",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: theme.colors.textDark,
          fontSize: "14px",
          fontWeight: "400",
          borderWidth: "0",
          VerticalAlign: "middle",
          padding: "20px 16px",
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& tr:nth-of-type(odd) td": {
            backgroundColor: theme.colors.tableStriped,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.MuiTableRow-hover": {
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          backgroundColor: theme.colors.white,
          "& .MuiTableRow-root": {
            //padding:'16px 0px 0px 0px',
            "& .MuiTableCell-footer": {
              padding: "16px 0px 0px 16px",
              "& .MuiTablePagination-root": {
                padding: "0px",
                "& .MuiTablePagination-toolbar": {
                  minHeight: "auto",
                  "& .MuiTablePagination-selectLabel": {
                    margin: "0px",
                    order: "2",
                  },
                  "& .MuiTablePagination-input": {
                    marginLeft: "0px",
                    marginRight: "16px",
                  },
                  "& .MuiTablePagination-select": {
                    marginLeft: "0px",
                    padding: "12px",
                    backgroundColor: theme.colors.surfaceBg,
                    borderRadius: theme.colors.themeRadius,
                    boxShadow: theme.colors.themeBoxShadow,
                    minWidth: "45px",
                    textAlign: "left",
                    textAlignLast: "left",
                  },
                },
              },
              "& .MuiButton-root": {
                minWidth: "auto",
                boxShadow: "none",
                padding: "8px 8px",
                fontSize: "16px",
              },
              "& .pagination-number": {
                padding: "7px 12px",
                backgroundColor: "#35B0FF",
                fontWeight: "600",
                color: theme.colors.white,
                borderRadius: theme.colors.themeRadius,
                minWidth: "32px",
                fontSize: "14px",
                lineHeight: "18px",
              },
            },
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
        rounded: {
          borderRadius: theme.colors.themeRadius,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          marginBottom: "24px",
          "&:last-child": {
            marginBottom: "0px",
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "16px",
          "&:last-child": {
            paddingBottom: "16px",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          "& > .MuiTypography-root": {
            letterSpacing: "0.24px",
            textTransform: "uppercase",
            color: theme.colors.textSecondary,
            fontWeight: "600",
            fontSize: "12px",
            padding: "8px 0px",
            "& .MuiListItemButton-root": {},
          },
          "& .MuiListItem-root": {
            padding: "0px",
            "& :hover": {
              backgroundColor: theme.colors.lightPrimary,
              color: theme.colors.primary,
            },
          },
          "& .MuiListItemButton-root": {
            padding: "12px",
            borderRadius: "6px",
          },
          "& .MuiListItemIcon-root": {
            minWidth: "auto",
            marginRight: "4px",
          },
          "& .MuiListItemText-root": {
            margin: "0px",
            color: theme.colors.textSecondary,
            fontSize: "14px",
          },
          "&.MuiMenu-list": {
            padding: "0px!important",
            width: "100%!important",
            boxShadow: theme.colors.themeBoxShadow,
            borderRadius: theme.colors.themeRadius,
            "& .MuiMenuItem-root": {
              fontSize: "14px",
              "&:hover": {
                color: theme.colors.primary,
              },
              "&.Mui-selected": {
                color: theme.colors.primary,
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            },
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          marginTop: "8px",
          marginBottom: "40px",
          fontSize: "12px",
          color: theme.colors.textSecondary,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          "& .MuiStepLabel-iconContainer": {
            "& > div": {
              width: "34px",
              height: "34px",
              backgroundColor: theme.colors.surfaceBg,
              color: theme.colors.textSecondary,
              fontWeight: "600",
            },
            "&.Mui-active": {
              "& > div": {
                backgroundColor: theme.colors.primary,
                color: theme.colors.white,
              },
            },
          },
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          "&.MuiStep-horizontal": {
            "& .MuiStepConnector-horizontal": {
              top: "15px",
              left: "calc(-50% + 16px)",
              right: "calc(50% + 16px)",
              "& .MuiStepConnector-line": {
                height: "4px",
                borderRadius: theme.colors.themeRadius,
                backgroundColor: theme.colors.surfaceBg,
              },
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginBottom: "16px",
          marginLeft: "0px",
          "& .MuiCheckbox-root": {
            padding: "0px",
            marginRight: "8px",
          },
          "& .MuiTypography-root": {
            fontSize: "12px",
            color: theme.colors.textSecondary,
          },
        },
      },
    },
  };
}
