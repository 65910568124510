import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  query: "",
};
const globalsearchSlice = createSlice({
  name: "globalsearchSlice",
  initialState: initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      state.query = action.payload;
    },
  },
});

export const { setSearchQuery } = globalsearchSlice.actions;

export default globalsearchSlice.reducer;
