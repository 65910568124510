import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  ListItemIcon,
  IconButton,
  useMediaQuery,
  Avatar,
  FormControl,
  Box,
  InputAdornment,
  Select,
} from "@mui/material";
import ArrowLeftIcon from "../../assets/images/icon/ArrowLeft.svg";
import ArrowRightIcon from "../../assets/images/icon/ArrowRight.svg";
import chipIcon from "../../assets/images/icon/chipIcon.svg";
import profileAvatar from "../../assets/images/profileAvatar.jpeg";
import Logout from "@mui/icons-material/Logout";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

import { setLogout } from "../../redux/slices/auth/signinSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { SuccessToast } from "../../utils/Toaster";
import { selectStoreList } from "../../redux/slices/storeListSlice";
import { getStoreList } from "../../redux/thunk/storeListThunk";
import { getDashBoardData } from "../../redux/thunk/dashboard/dashboardThunk";
import { setSelectedSotreForRedux } from "../../redux/slices/dashboard/dashboardSlice";

const drawerWidth = 240;
const closedDrawerWidth = 56;

function Navbar({ handleDrawerToggle, open }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { accounts } = useSelector((state) => state.root.login?.user);
  const storeList = useSelector(selectStoreList);
  const selectedStoreId = useSelector(
    (state) => state.root.dashBoardState.selectedStore
  );
  const [selectedStore, setSelectedStore] = useState(
    selectedStoreId
      ? selectedStoreId
      : storeList?.[0]?.id || accounts?.data[0]?.id
  );

  //const mobileMenuId = "primary-search-account-menu-mobile";

  const handleClose = () => {
    dispatch(setLogout());
    dispatch({ type: "RESET_STORE" });
    localStorage.removeItem("token");
    navigate("/login");
    setAnchorEl(null);
    SuccessToast("User logged out successfully");
    setIsMenuOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  useEffect(() => {
    dispatch(getStoreList());
    selectedStoreId
      ? dispatch(setSelectedSotreForRedux(selectedStoreId))
      : dispatch(
          setSelectedSotreForRedux(storeList?.[0]?.id || accounts?.data[0]?.id)
        );
  }, []);

  return (
    <AppBar
      position="fixed"
      className="topbar"
      sx={{
        width: `calc(100% - ${
          !isSmallScreen ? (open ? drawerWidth : closedDrawerWidth) : 0
        }px)`,
        marginLeft: `${
          !isSmallScreen ? (open ? drawerWidth : closedDrawerWidth) : 0
        }px`,
        transition: "0.3s",
      }}
    >
      <div className="topbarLeft">
        <Toolbar>
          <>
            {!isSmallScreen && (
              <IconButton onClick={handleDrawerToggle}>
                <img
                  src={open ? ArrowLeftIcon : ArrowRightIcon}
                  alt="Toggle Arrow"
                />
              </IconButton>
            )}
          </>
        </Toolbar>
      </div>
      <div className="topbarRight">
        {location?.pathname === "/" && (
          <Box style={{ gap: "4px" }}>
            <FormControl fullWidth>
              <Select
                sx={{ minWidth: "190px" }}
                value={selectedStore}
                MenuProps={{
                  disableScrollLock: true,
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <img src={chipIcon} alt="chip Icon" />
                  </InputAdornment>
                }
                IconComponent={UnfoldMoreIcon}
                onChange={(e) => {
                  dispatch(setSelectedSotreForRedux(e.target.value));
                  setSelectedStore(e.target.value);
                  dispatch(
                    getDashBoardData({
                      pageNum: 1,
                      perPageNum: 10,
                      account_id: e.target.value,
                    })
                  );
                }}
              >
                {storeList?.length > 0 &&
                  storeList?.map((item, i) => (
                    <MenuItem key={item?.id} value={item.id}>
                      {item?.name ? item?.name : "Name Not Found"}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}
        <div>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ p: 0, ml: 0, width: 40, height: 40 }}
            aria-controls={isMenuOpen ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={isMenuOpen ? "true" : undefined}
          >
            <Avatar alt="" variant="rounded" src={profileAvatar} />
          </IconButton>
          <Menu
            onClick={() => setIsMenuOpen(false)}
            anchorEl={anchorEl}
            id="account-menu"
            open={isMenuOpen}
            disableScrollLock
          >
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </div>
    </AppBar>
  );
}

export default Navbar;
