import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import doubleTick from "../assets/images/double-check.png";

export default function LoderForStapper(props) {
  const { value, variant, sx, className, showLabel, syncData } = props;
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress {...props} />
      <Box
        sx={{
          top: 30,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {showLabel === "true" && syncData === true ? (
          <Typography variant="caption" component="div" color="text.secondary">
            <img src={doubleTick} alt="" width={48} height={48} />
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
}
