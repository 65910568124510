import React from "react";
import { useSelector } from "react-redux";
const withHighlight = (Component) => {
  return (props) => {
    const query = useSelector(
      (state) => state.root?.globalSearchState?.query || "",
    );

    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters for RegExp
    };

    const highlightSearch = (text) => {
      // Ensure query exists
      if (!query || query.length === 0) {
        return text;
      }

      const escapedQuery = escapeRegExp(query); // Escape special characters

      // Handle text as number
      if (typeof text === "number") {
        const stringText = text.toString();
        const parts = stringText.split(new RegExp(`(${escapedQuery})`, "gi"));

        return (
          <>
            {parts.map((part, index) =>
              part.toLowerCase() === escapedQuery.toLowerCase() ? (
                <span key={index} style={{ backgroundColor: "yellow" }}>
                  {part}
                </span>
              ) : (
                part
              ),
            )}
          </>
        );
      }

      // Handle text as string
      const parts = text.split(new RegExp(`(${escapedQuery})`, "gi"));

      return (
        <>
          {parts.map((part, index) =>
            part.toLowerCase() === escapedQuery.toLowerCase() ? (
              <span key={index} style={{ backgroundColor: "yellow" }}>
                {part}
              </span>
            ) : (
              part
            ),
          )}
        </>
      );
    };

    return <Component {...props} highlightSearch={highlightSearch} />;
  };
};

export default withHighlight;
