import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  Button,
  Grid,
  Step,
  StepLabel,
  StepConnector,
  Stepper,
  Typography,
} from "@mui/material";
import { stepConnectorClasses } from "@mui/material/StepConnector";
import { useDispatch, useSelector } from "react-redux";
import { getTimeStampForStore } from "../../redux/thunk/auth/authThunk";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#003399", // Use the blue color
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#003399", // Use the blue color
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#003399", // Use the blue color
  }),
  ...(ownerState.completed && {
    backgroundColor: "#003399", // Use the blue color
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const StepperHandler = ({ steps, getStepContent, buttonref }) => {
  const [activeStep, setActiveStep] = useState(0);
  const { timeStampForStore } = useSelector((state) => state.root.signup);
  const is_walmart_account_detail = useSelector(
    (state) => state.root.login?.user?.is_walmart_account_detail,
  );
  const isAuthenticated = useSelector(
    (state) => state.root.login?.isAuthenticated,
  );
  const { isStoreCreates } = useSelector((state) => state.root.signup);
  const dispatch = useDispatch();
  const handleNext = () => {
    console.log("Hello");
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    dispatch(getTimeStampForStore());
  }, []);

  // useEffect(() => {
  //   const storedTime = new Date(timeStampForStore).getTime();
  //   const currentTime = new Date().getTime();
  //   const elapsedTime = (currentTime - storedTime) / (1000 * 60 * 60);
  //   if (isAuthenticated) {
  //     if (elapsedTime <= 24 && is_walmart_account_detail) {
  //       setActiveStep(3);
  //     }
  //   } else {
  //     if (elapsedTime <= 24) {
  //       if (isStoreCreates) {
  //         setActiveStep(3);
  //       }
  //     }
  //   }
  // }, [timeStampForStore]);

  return (
    <>
      <Grid container justifyContent="center" spacing={2} sx={{ mb: 7 }}>
        <Grid item xs={12} sm={12} md={5}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
      <>
        {activeStep === steps.length ? (
          <Grid item>
            <Typography>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </Grid>
        ) : (
          <Grid item>
            {getStepContent(activeStep)}
            {/* <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button> */}
            <Button
              sx={{ visibility: "hidden" }}
              variant="contained"
              onClick={handleNext}
              ref={buttonref}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Grid>
        )}
      </>
    </>
  );
};

export default StepperHandler;
