import {
  Button,
  Chip,
  Grid,
  InputLabel,
  TextField,
  Typography,
  Card,
  CardContent,
  Divider,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import DialogSlide from "../../../components/Dilog";
import TextFieldComponent from "../../../components/TextFieldComponent";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  addCardThunk,
  deleteCardThunk,
  editCardThunk,
  listCardThunk,
} from "../../../redux/thunk/payment/addpaymetdetailsThunk";
import { useDispatch, useSelector } from "react-redux";
import withHighlight from "../../../components/GlobalSearch/withHighlight";
import HeaderNevigation from "../../../components/HeaderNevigation/HeaderNevigation";
import { ErrorToast } from "../../../utils/Toaster";

const CustomChip = ({ label }) => {
  const chipConfig = {
    Active: { color: "success", updatedLabel: "Active" },
    InActive: {
      color: "error",
      updatedLabel: "InActive",
    },
  };

  const { color, updatedLabel, textColor } = chipConfig[label] || {};

  return <Chip label={updatedLabel} size="small" color={color} />;
};

const DateInput = ({ value, onChange }) => {
  const handleInputChange = (e) => {
    let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2) + "/" + inputValue.slice(2);
    }
    onChange({
      target: {
        name: "cardExp", // Name should match the name field expected in handleChangeForEdit
        value: inputValue,
      },
    });
  };

  return (
    <TextField
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: "6px",
        gap: "10px",
        backgroundColor: "#EBF6FF",
      }}
      value={value}
      onChange={handleInputChange}
      placeholder="MM/YY"
      inputProps={{ maxLength: 5 }} // Restricting the input length to MM/YY
    />
  );
};

function PaymentMethod({ highlightSearch }) {
  const [openModel, setOpenModel] = useState();
  const [editModal, setOpenForEdit] = useState();
  const [cardNickname, setCardNickname] = useState("");
  const [nameOnCard, setNameOnCard] = useState("");
  const [cardNicknameError, setCardNicknameError] = useState("");
  const [nameOnCardError, setNameOnCardError] = useState("");
  const { cardlist } = useSelector((state) => state?.root?.paymetState);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [showCardlist, setShowCardList] = useState();
  const [initialValuesForEdit, setInitailValuesForEdit] = useState({
    nickname: "",
    cardExp: "",
    name: "",
    id: "",
    cardholder_name: "",
  });
  const [errorsForEdit, setErrorsForEdit] = useState({
    nickname: "",
    cardExp: "",
    cardholder_name: "",
  });

  const headerData = [
    {
      linkName: "Setting",
      link: "/paymentmethod",
      active: false,
    },
    {
      linkName: "Payment Method",
      link: "/paymentmethod",
      active: true,
    },
  ];

  useEffect(() => {
    dispatch(listCardThunk());
  }, []);

  useEffect(() => {
    if (cardlist && cardlist.length > 0) {
      setShowCardList(true);
    } else {
      setShowCardList(false);
    }
  }, [cardlist]);

  const handleCloseForAdd = () => {
    setOpenModel(false);
    elements.getElement(CardNumberElement).clear();
    elements.getElement(CardExpiryElement).clear();
    elements.getElement(CardCvcElement).clear();
    setCardNickname("");
    setNameOnCard("");
    setNameOnCardError("");
    setCardNicknameError("");
  };

  const handleChangeForAdd = (event) => {
    const { name, value } = event.target;

    // Regex to allow only letters and spaces for name fields
    const isTextValid = /^[A-Za-z\s]*$/.test(value);

    switch (name) {
      case "name_on_card": // Handle card nickname
        if (!isTextValid) {
          setCardNicknameError("Only letters are allowed in Card Nickname");
        } else {
          setCardNicknameError(""); // Clear error if valid
          setCardNickname(value); // Update state
        }
        break;

      case "card_nick_name": // Handle name on card
        if (!isTextValid) {
          setNameOnCardError("Only letters are allowed in Name on Card");
        } else {
          setNameOnCardError(""); // Clear error if valid
          setNameOnCard(value); // Update state
        }
        break;

      default:
        // Handle other fields if needed
        break;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let isValid = true;

    // Validation
    if (!cardNickname) {
      setCardNicknameError("Card nickname is required");
      isValid = false;
    } else {
      setCardNicknameError("");
    }

    if (!nameOnCard) {
      setNameOnCardError("Name on card is required");
      isValid = false;
    } else {
      setNameOnCardError("");
    }

    if (!isValid) {
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });

    if (error) {
      ErrorToast(error);
    } else {
      const cardDetails = {
        nickname: cardNickname,
        cardholder_name: nameOnCard,
        payment_method_id: paymentMethod.id,
      };

      dispatch(addCardThunk(cardDetails));
      setOpenModel(false);
      handleCloseForAdd();
    }
  };

  const handleOpenEdit = (item) => {
    setOpenForEdit(true);

    const editData = {
      id: item.id,
      nickname: item.nickname,
      cardExp: `${item.exp_month}/${item.exp_year % 100}`,
      cardholder_name: item.cardholder_name,
    };

    setInitailValuesForEdit(editData);
  };

  const validateField = (name, value) => {
    let error = "";

    if (!value.trim()) {
      error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
    }

    // Validation for name and nickname (text-only)
    if (
      (name === "cardholder_name" || name === "nickname") &&
      !/^[A-Za-z\s]*$/.test(value)
    ) {
      error = `${
        name.charAt(0).toUpperCase() + name.slice(1)
      } should contain only letters and spaces`;
    }

    return error;
  };

  const validateAllFields = () => {
    let tempErrors = {};
    Object.keys(initialValuesForEdit).forEach((field) => {
      tempErrors[field] = validateField(field, initialValuesForEdit[field]);
    });
    setErrorsForEdit(tempErrors);
    return Object.values(tempErrors).every((error) => error === "");
  };

  const handleChangeForEdit = (event) => {
    const { name, value } = event.target;

    // Determine if the field is required
    const isRequired = name === "cardholder_name" || name === "nickname";

    // Prevent numbers in name and nickname fields and handle required fields
    if (name === "cardholder_name" || name === "nickname") {
      const textOnly = /^[A-Za-z\s]*$/;

      // Handle text validation
      if (!textOnly.test(value)) {
        const error =
          name === "cardholder_name"
            ? "Only letters are allowed in Name on Card"
            : "Only letters are allowed in Card Nickname";
        setErrorsForEdit((prevErrors) => ({
          ...prevErrors,
          [name]: error,
        }));
        return; // Do not update the state if invalid input is detected
      }

      // Handle required field validation
      if (isRequired && value.trim() === "") {
        // Only set error for required fields if they are blank
        const error =
          name === "cardholder_name"
            ? "Name on Card is required"
            : "Card Nickname is required";
        setErrorsForEdit((prevErrors) => ({
          ...prevErrors,
          [name]: error,
        }));
      } else {
        // Clear error if input is valid or not required
        setErrorsForEdit((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }

    // Update state based on the input field
    setInitailValuesForEdit((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCloseOnEdit = () => {
    setOpenForEdit(false);
    setInitailValuesForEdit({
      nickname: "",
      cardExp: "",
      name: "",
      id: "",
      cardholder_name: "",
    });
    setErrorsForEdit({
      nickname: "",
      cardExp: "",
      cardholder_name: "",
    });
  };

  const handleSubmitForEdit = (data) => {
    data.preventDefault();

    if (validateAllFields()) {
      const [month, year] = initialValuesForEdit.cardExp.split("/");
      const exp_month = month; // MM part
      const exp_year = parseInt(year.slice(-2), 10);

      const request = {
        payment_method_id: initialValuesForEdit.id,
        cardholder_name: initialValuesForEdit.cardholder_name,
        nickname: initialValuesForEdit.nickname,
        exp_month: parseInt(exp_month),
        exp_year: exp_year,
      };

      dispatch(editCardThunk(request));
      setOpenForEdit(false);
    } else {
      // Display error message if necessary
    }
  };

  return (
    <>
      <HeaderNevigation headerData={headerData} />

      <Card>
        <CardContent>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography
                variant="headingXXXS"
                component="h4"
                sx={{ fontSize: "16px", letterSpacing: "auto" }}
              >
                {highlightSearch("Payment Method")}
              </Typography>
            </div>
            <div>
              {showCardlist && (
                <Button
                  onClick={() => setOpenModel(true)}
                  startIcon={<IoMdAdd />}
                  variant="contained"
                  color="primary"
                >
                  Link New Card
                </Button>
              )}
            </div>
          </div>
          <Divider sx={{ pt: 2, mb: 3 }} />

          {!showCardlist && (
            <div style={{ textAlign: "center" }}>
              <Typography variant="bodyXS" component="p" mb={2}>
                {highlightSearch(
                  "Add a payment method to manage your subscription with Refund Stacker",
                )}{" "}
              </Typography>
              <Button
                sx={{ mb: 2 }}
                onClick={() => setOpenModel(true)}
                startIcon={<IoMdAdd />}
                variant="contained"
                color="primary"
              >
                Add Card
              </Button>
            </div>
          )}
          {showCardlist && (
            <>
              {cardlist?.map((item, i) => (
                <>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6} md={6}>
                      <Stack direction="row" spacing={1}>
                        <div>
                          <Typography
                            variant="headingXXXS"
                            sx={{ fontSize: "16px", letterSpacing: "normal" }}
                          >
                            {highlightSearch(item?.nickname)}
                          </Typography>{" "}
                        </div>
                        <div>
                          <CustomChip label={"Active"} />
                        </div>
                      </Stack>
                      <Typography mt={1} variant="bodyS">
                        {`${item.brand}****${item.last4}`}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      sx={{ textAlign: "right" }}
                    >
                      <Button
                        onClick={() => handleOpenEdit(item)}
                        sx={{ mr: 2 }}
                        variant="outlined"
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={() => {
                          dispatch(
                            deleteCardThunk({ payment_method_id: item.id }),
                          );
                        }}
                        variant="outlined"
                        color="error"
                      >
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                  {cardlist?.length !== i + 1 && (
                    <Divider sx={{ mt: 2, mb: 2 }} />
                  )}
                </>
              ))}
            </>
          )}
          <DialogSlide
            fullWidth={true}
            width="600px"
            open={openModel}
            title={
              <Typography
                sx={{ color: "#101010", fontWeight: 600, fontSize: "16px" }}
              >
                {highlightSearch("Billing Information")}
              </Typography>
            }
            handleClose={handleCloseForAdd}
          >
            <form onSubmit={handleSubmit}>
              <Grid container rowSpacing={2} spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid item xs={12}>
                    <TextFieldComponent
                      id="name_on_card" // This is fine; ensure the name matches the handleChange function
                      type="text"
                      label="Card Nickname"
                      name="name_on_card" // This should match the case in handleChangeForAdd
                      placeholder="Enter nick name"
                      value={cardNickname}
                      onChange={handleChangeForAdd}
                      error={cardNicknameError}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextFieldComponent
                    id="card_nick_name" // This is fine; ensure the name matches the handleChange function
                    name="card_nick_name" // This should match the case in handleChangeForAdd
                    type="text"
                    label="Name on card"
                    placeholder="Enter name on card"
                    value={nameOnCard}
                    onChange={handleChangeForAdd}
                    error={nameOnCardError}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <InputLabel htmlFor="card-number">
                    {highlightSearch("Card Number")}
                  </InputLabel>

                  <div
                    style={{
                      borderRadius: "6px",
                      overflow: "hidden", // Ensures that the border radius applies to the container
                      border: "1px solid #ced4da",
                      backgroundColor: "#EBF6FF",
                      padding: "4px 12px", // Ensure padding is zero to avoid extra spacing
                      border: "none",
                    }}
                  >
                    <CardNumberElement
                      options={{
                        style: {
                          base: {
                            fontSize: "16px",
                            lineHeight: "40px",
                            color: "#424770",
                            fontFamily:
                              "Roboto, Open Sans, Segoe UI, sans-serif",
                            fontSmoothing: "antialiased",
                            padding: "16px",
                            backgroundColor: "#EBF6FF",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                            boxSizing: "border-box",
                            "::placeholder": {
                              color: "gray",
                            },
                          },
                          invalid: {
                            color: "#9D1444",
                          },
                        },
                      }}
                    />
                  </div>
                </Grid>

                <Grid container item spacing={1}>
                  <Grid item xs={6}>
                    <InputLabel
                      sx={{
                        color: "#474747",
                        fontSize: "12px",
                        fontWeight: 500,
                        marginBottom: "4px",
                      }}
                      htmlFor="expiry-date"
                    >
                      {highlightSearch("Expiration Date")}
                    </InputLabel>
                    <div
                      style={{
                        borderRadius: "6px",
                        overflow: "hidden",
                        border: "1px solid #ced4da",
                        backgroundColor: "#EBF6FF",
                        padding: "4px 12px",
                        border: "none",
                      }}
                    >
                      <CardExpiryElement
                        options={{
                          style: {
                            base: {
                              fontSize: "16px",
                              lineHeight: "40px",
                              color: "#424770",
                              fontFamily:
                                "Roboto, Open Sans, Segoe UI, sans-serif",
                              fontSmoothing: "antialiased",
                              padding: "16px",
                              backgroundColor: "#EBF6FF",
                              boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                              boxSizing: "border-box",
                              "::placeholder": {
                                color: "gray",
                              },
                            },
                            invalid: {
                              color: "#9D1444",
                            },
                          },
                        }}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel
                      sx={{
                        color: "#474747",
                        fontSize: "12px",
                        fontWeight: 500,
                        marginBottom: "4px",
                      }}
                      htmlFor="cvc"
                    >
                      {highlightSearch("CVC")}
                    </InputLabel>
                    <div
                      style={{
                        borderRadius: "6px",
                        overflow: "hidden",
                        border: "1px solid #ced4da",
                        backgroundColor: "#EBF6FF",
                        padding: "4px 12px",
                        border: "none",
                      }}
                    >
                      <CardCvcElement
                        options={{
                          style: {
                            base: {
                              fontSize: "16px",
                              lineHeight: "40px",
                              color: "#424770",
                              fontFamily:
                                "Roboto, Open Sans, Segoe UI, sans-serif",
                              fontSmoothing: "antialiased",
                              padding: "16px",
                              backgroundColor: "#EBF6FF",
                              boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                              boxSizing: "border-box",
                              "::placeholder": {
                                color: "gray",
                              },
                            },
                            invalid: {
                              color: "#9D1444",
                            },
                          },
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container xs={12} mt={3} justifyContent="end" gap={2}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={handleCloseForAdd}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" variant="contained" type="submit">
                    Save card
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogSlide>

          <DialogSlide
            fullWidth={true}
            width="600px"
            open={editModal}
            title={
              <Typography
                sx={{ color: "#101010", fontWeight: 600, fontSize: "16px" }}
              >
                {highlightSearch("Edit Billing Information")}
              </Typography>
            }
            handleClose={handleCloseOnEdit}
          >
            <form onSubmit={handleSubmitForEdit}>
              <Grid container spacing={2}>
                {/* Card Nickname */}
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <TextFieldComponent
                      id="nickname"
                      label="Card Nickname"
                      type="text"
                      name="nickname"
                      placeholder="Enter nick name"
                      value={initialValuesForEdit.nickname}
                      onChange={handleChangeForEdit}
                      error={errorsForEdit.nickname}
                    />
                  </Grid>
                </Grid>

                {/* Cardholder Name */}
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <TextFieldComponent
                      id="cardholder_name"
                      label="Name on card"
                      name="cardholder_name"
                      type="text"
                      placeholder="Enter name on card"
                      value={initialValuesForEdit.cardholder_name}
                      onChange={handleChangeForEdit}
                      error={errorsForEdit.cardholder_name}
                    />
                  </Grid>
                </Grid>

                {/* Expiration Date */}
                <Grid item xs={12}>
                  <InputLabel
                    sx={{
                      color: "#474747",
                      fontSize: "12px",
                      fontWeight: 500,
                      marginBottom: 1,
                    }}
                  >
                    {highlightSearch("Expiration Date")}
                  </InputLabel>
                  <Grid item xs={12}>
                    <DateInput
                      name="cardExp"
                      value={initialValuesForEdit?.cardExp}
                      onChange={(e) => {
                        setInitailValuesForEdit({
                          ...initialValuesForEdit,
                          cardExp: e.target.value,
                        });
                        handleChangeForEdit(e);
                      }}
                      error={errorsForEdit.cardExp}
                    />
                  </Grid>
                </Grid>

                {/* Submit and Cancel Buttons */}
                <Grid container xs={12} mt={3} justifyContent="end" gap={2}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={handleCloseOnEdit}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" variant="contained" type="submit">
                    Edit card
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogSlide>
        </CardContent>
      </Card>
    </>
  );
}

export default withHighlight(PaymentMethod);
