import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { validateResetSubmit } from "../../../utils/Validation";
import { useDispatch } from "react-redux";
import TextFieldComponent from "../../../components/TextFieldComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import Logo from "../../../assets/images/logo/logo.svg";
import { resetPasswordHandle } from "../../../redux/thunk/auth/authThunk";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [errors, setErrors] = useState({});
  const [togglePassword, settogglePassword] = React.useState({
    password: false,
    confirmPassword: false,
  });
  const [formSubmit, setFormSubmit] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormSubmit({
      ...formSubmit,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "", // Clear the error for the current field
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateResetSubmit(formSubmit);
    if (!isValid) {
      setErrors(errors);
    } else {
      let req = {
        apiData: {
          uid: pathname?.split("/")[2],
          token: pathname?.split("/")[3],
          new_password: formSubmit?.password,
        },
        navigate: navigate,
      };
      dispatch(resetPasswordHandle(req));
    }
  };
  const toggleHandlerId = (id) => {
    "confirmPassword" === id
      ? settogglePassword({
          ...togglePassword,
          confirmPassword: !togglePassword?.confirmPassword,
        })
      : settogglePassword({
          ...togglePassword,
          password: !togglePassword?.password,
        });
  };

  return (
    <>
      <div className="authPage SignIn">
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6} md={6}>
            <div className="authLeft">
              <div className="authTopContent">
                <div className="inner">
                  <Typography
                    variant="headingS"
                    className="text-primary"
                    component="h2"
                    sx={{ letterSpacing: "normal", mb: 0 }}
                  >
                    Save your time and money
                  </Typography>
                  <Typography
                    className="text-dark"
                    component="p"
                    sx={{ fontSize: "18px", mb: 0 }}
                  >
                    As experienced sellers ourselves, we know exactly what works
                    and what doesn’t. You only pay if we’re successful.
                  </Typography>
                </div>
              </div>
              <div className="authBottomContent"></div>
              <div className="authLogo">
                <img src={Logo} alt="Logo" />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} sm={8}>
                <div
                  className="authForm"
                  style={{
                    display: "flex",
                    padding: "0px 16px",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    gap: "16px",
                  }}
                >
                  <Box
                    className="logo"
                    sx={{ display: { sm: "block", md: "none" } }}
                  >
                    <img src={Logo} alt="Logo" />
                  </Box>
                  <div>
                    <Typography
                      variant="headingXS"
                      component="h3"
                      sx={{ letterSpacing: "normal" }}
                    >
                      Reset Password In to RefundStacker
                    </Typography>
                    <Typography
                      variant="bodyM"
                      component="p"
                      sx={{ letterSpacing: "normal", mb: 2 }}
                    >
                      Generate New Password Here{" "}
                    </Typography>
                  </div>
                  <TextFieldComponent
                    id="password"
                    name="password"
                    label="Password"
                    type={togglePassword?.password ? "text" : "password"}
                    value={formSubmit?.password}
                    onChange={handleInputChange}
                    error={errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => toggleHandlerId("password")}
                            edge="end"
                          >
                            {togglePassword?.password ? (
                              <IoEye />
                            ) : (
                              <IoEyeOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextFieldComponent
                    id="confirmPassword"
                    name="confirmPassword"
                    label="Confirm Password"
                    type={togglePassword?.confirmPassword ? "text" : "password"}
                    value={formSubmit?.confirmPassword}
                    onChange={handleInputChange}
                    error={errors.confirmPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => toggleHandlerId("confirmPassword")}
                            edge="end"
                          >
                            {togglePassword?.confirmPassword ? (
                              <IoEye />
                            ) : (
                              <IoEyeOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    sx={{ mt: 2 }}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ResetPassword;
