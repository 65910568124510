import { createAsyncThunk } from "@reduxjs/toolkit";
import { authApiWithToken } from "../../../utils/authApi";
import { setLoading } from "../../slices/dataLoadingSlice";
import { SuccessToast } from "../../../utils/Toaster";
import { loginUser } from "../auth/authThunk";

// get store data
export const updateAccountData = createAsyncThunk(
  "update/accountdata",
  async (request, { dispatch, getState }) => {
    try {
      const state = getState();
      const loginCreds = state?.root?.login?.loginCreds;

      const id = request?.id;
      const response = await authApiWithToken.put(
        `account/user/profile/${id}/`,
        { ...request.formData },
      );
      dispatch(setLoading(false));
      if (response.data.success === true) {
        SuccessToast(response.data.message);
        dispatch(
          loginUser({
            apiData: request?.formData?.email ? {
              email: request?.formData?.email,
              password: loginCreds?.password
            } : {
              ...loginCreds
            },
            fromUpdateAccount: true,
          }),
        );
      }
    } catch (error) {
      console.log("error: ", error);
      dispatch(setLoading(false));
    }
  },
);
