import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import withHighlight from "../GlobalSearch/withHighlight";

function HeaderNevigation({ headerData, highlightSearch }) {
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        {headerData?.map((item, index) => (
          <RouterLink
            key={index}
            to={item.link}
            style={{
              textDecoration: "none",
              color: item.active ? "#101010" : "#474747", // Change color based on active state
              //   pointerEvents: item.active ? 'auto' : 'none', // Disable pointer events for inactive links
            }}
          >
            {highlightSearch(item.linkName)}
          </RouterLink>
        ))}
      </Breadcrumbs>
    </div>
  );
}

export default withHighlight(HeaderNevigation);
