import * as React from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  DialogActions,
  Grid,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogSlide = ({
  open,
  handleClose,
  children,
  title,
  fullWidth,
  maxWidth,
  actions,
  coustomClass,
}) => {
  return (
    <Dialog
      className={coustomClass}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: { borderRadius: "12px", padding: "8px 2px" },
      }}
    >
      <Grid>
        <DialogTitle sx={{ pr: 5, mr: 5, my: -1 }}>
          <Typography color="white" variant="h4">
            {title}
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 5,
            //   color: (theme) => theme.palette.grey[500]
            color: "black",
          }}
        >
          <IoMdClose />
        </IconButton>
      </Grid>

      <DialogContent>{children}</DialogContent>
      {/* <DialogActions sx={{ display: 'flex', justifyContent: 'end', pr: 2 }}>{actions}</DialogActions> */}
    </Dialog>
  );
};

export default DialogSlide;
