import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App.js";
import { RTKProvider } from "./provider/rtk_provider.js";

// console.log = function () {};
// console.warn = function () {};
// console.error = function () {}
ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <BrowserRouter>
      <RTKProvider>
        <App />
      </RTKProvider>
    </BrowserRouter>
  </>,
);
