import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cardlist: [],
};

const addpaymentSlice = createSlice({
  name: "addpaymentSlice",
  initialState,
  reducers: {
    setCardList: (state, action) => {
      return {
        ...state,
        cardlist: action.payload,
      };
    },
  },
});

export const { setCardList } = addpaymentSlice.actions;

export default addpaymentSlice.reducer;
