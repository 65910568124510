import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

const dataLoadingSlice = createSlice({
  name: "data_loading",
  initialState,
  reducers: {
    setLoading: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
  },
});

export const { setLoading } = dataLoadingSlice.actions;

export default dataLoadingSlice.reducer;
