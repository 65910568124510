import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Authorized from "../../components/Authorized/Authorized";
import MainLayout from "../MainLayout/MainLayout";
import ProfileAndAcount from "../../View/pages/ProfileAndAcount/ProfileAndAcount";
import UnAuthorized from "../../components/UnAuthorized/UnAuthorized";
import MinimalLayout from "../MinimalLayout/MinimalLayout";
import PaymentMethod from "../../View/pages/PaymentMethod/PaymentMethod";
import TableView from "../../View/pages/Dashbaordtabelview/TableView";
import StoreDetailsView from "../../View/pages/Storedetailview/StoreDetailsView";
import CreateStore from "../../View/pages/StoreDetails/create";
import PageNotFound from "../../components/PageNotFound/PageNotFound";
import Login from "../../View/auth/SignIn/Login";
import SignUp from "../../View/auth/SignUp/SignUp";
import SignUpSubmit from "../../View/auth/SignUp/SignupSubmit";
import ResetPassword from "../../View/auth/resetPassword";
import ConfirmEmail from "../../View/auth/resetPassword/confirmEmail";
import IsAuthorized from "../../components/isAuthorized";
import IsForbidden from "../../components/isForbidden";
const Dashboard = lazy(() => import("../../View/pages/Dashboard"));
const StoreDetails = lazy(() => import("../../View/pages/StoreDetails"));

const Rounting = () => {
  return (
    <Routes>
      {/* Dashboard Routs */}
      <Route element={<Authorized />}>
        <Route element={<MainLayout />}>
          <Route
            path="/"
            element={
              <Suspense fallback={<>...</>}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route path="/profileandacount" element={<ProfileAndAcount />} />
          <Route
            path="/storedeatils"
            element={
              <Suspense fallback={<>...</>}>
                <StoreDetails />
              </Suspense>
            }
          />
          <Route path="/paymentmethod" element={<PaymentMethod />} />
          <Route path="/cashdetails/:id" element={<TableView />} />
          <Route path="/storedetailsview/:id" element={<StoreDetailsView />} />
          <Route path="/storedetailsview" element={<CreateStore />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Route>

      {/* AuthRouts */}
      <Route element={<UnAuthorized />}>
        <Route element={<MinimalLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signupsubmit" element={<SignUpSubmit />} />
          <Route path="/walmart" element={<IsAuthorized />} />
          <Route path="/403" element={<IsForbidden />} />
          <Route path="/confirm-email" element={<ConfirmEmail />} />
          <Route path="/reset-password/:id/:id" element={<ResetPassword />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default Rounting;
