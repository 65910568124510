import { createAsyncThunk } from "@reduxjs/toolkit";
import { authApiWithToken } from "../../../utils/authApi";
import { setLoading } from "../../slices/dataLoadingSlice";
import { SuccessToast } from "../../../utils/Toaster";
import { setCardList } from "../../slices/paymentmethod/addpaymentSlice";

// add card
export const addCardThunk = createAsyncThunk(
  "add/card",
  async (request, { dispatch }) => {
    try {
      const response = await authApiWithToken.post(`api/add-card/`, {
        ...request,
      });
      dispatch(setLoading(false));

      if (response.data.success === true) {
        SuccessToast(response.data.message);
        dispatch(listCardThunk());
      }
    } catch (error) {
      console.log("error: ", error);
      dispatch(setLoading(false));
    }
  },
);

// update card
export const editCardThunk = createAsyncThunk(
  "add/card",
  async (request, { dispatch }) => {
    try {
      const response = await authApiWithToken.post(`api/edit-card/`, {
        ...request,
      });
      dispatch(setLoading(false));

      if (response.data.success === true) {
        SuccessToast(response.data.message);
        dispatch(listCardThunk());
      }
    } catch (error) {
      console.log("error: ", error);
      dispatch(setLoading(false));
    }
  },
);

//Delete card
export const deleteCardThunk = createAsyncThunk(
  "add/card",
  async (request, { dispatch }) => {
    try {
      const response = await authApiWithToken.post(`api/delete-card/`, {
        ...request,
      });
      dispatch(setLoading(false));

      if (response.data.success === true) {
        SuccessToast(response.data.message);
        dispatch(listCardThunk());
      }
    } catch (error) {
      console.log("error: ", error);
      dispatch(setLoading(false));
    }
  },
);

// list card
export const listCardThunk = createAsyncThunk(
  "add/card",
  async (request, { dispatch }) => {
    try {
      const response = await authApiWithToken.get(`api/list-cards/`);
      dispatch(setLoading(false));
      if (response.data.success === true) {
        dispatch(setCardList(response?.data?.data));
      }
    } catch (error) {
      console.log("error: ", error);
      // ErrorToast(error?.response?.data?.errors);
      dispatch(setLoading(false));
    }
  },
);
