import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { Box, Container, Paper } from "@mui/material";

const IsForbidden = () => {
  const { isExternal } = useSelector((state) => state.root?.login);

  return isExternal === false ? (
    <Container>
      <Paper elevation={3}>
        <Box component="h1">403 - Forbidden</Box>
        <Box component="p">You do not have permission to access this page.</Box>
        <Box component="a" href="/">
          Go to Home
        </Box>
      </Paper>
    </Container>
  ) : (
    <Navigate to="/login" />
  );
};

export default IsForbidden;
