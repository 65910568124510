import * as React from "react";
import { useSelector } from "react-redux";
import sidebarLogo from "../assets/images/logo/logoIcon.svg";

export default function Loader() {
  const loading = useSelector((state) => state.root.loading?.loading);

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="spinner" style={{ width: "80px", height: "80px" }}>
            <img
              src={sidebarLogo}
              className="loaderLogo"
              style={{
                top: "35%",
                left: "38%",
                transform: "translate(-35%, -35%)",
                width: "25px",
                height: "25px",
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
