import React from "react";
import Logo from "../../../assets/images/logo/logo.svg";
import GetStepper from "./GetStepper";

const WalmartAccLogin = () => {
  return (
    <>
      <div className="minimalHeader">
        <img src={Logo} alt=" Logo" width={160} height={22} />
      </div>
      <div className="minimalBody">
        <GetStepper />
      </div>
    </>
  );
};

export default WalmartAccLogin;
