import React from "react";
import { Outlet } from "react-router-dom";
import Loader from "../../components/Loader";

function MinimalLayout() {
  return (
    <>
      <Loader />
      <Outlet />
    </>
  );
}

export default MinimalLayout;
