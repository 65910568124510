import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import WalmartAccLogin from "../../View/auth/SetupWalmartAcc";

function IsAuthorized() {
  // Retrieve the token from localStorage
  const token = localStorage.getItem("token");
  const isDashboardAccessible = useSelector(
    (state) => state.root?.login?.isDashboardAccessible,
  );

  // Conditionally render based on the presence of the token
  return token ? (
    !isDashboardAccessible ? (
      <WalmartAccLogin />
    ) : (
      <Navigate to="/login" />
    )
  ) : (
    <Navigate to="/login" />
  );
}

export default IsAuthorized;
