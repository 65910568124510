/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */

export default function themeTypography(theme) {
  return {
    fontFamily: "Inter, Arial, sans-serif",
    headingXL: {
      fontSize: "52px",
      fontWeight: 600,
      letterSpacing: " -2%",
      color: theme.colors.textDark,
      fontFamily: "Inter, Arial, sans-serif",
    },
    headingL: {
      fontSize: "44px",
      fontWeight: 600,
      letterSpacing: " -2%",
      color: theme.colors.textDark,
      fontFamily: "Inter, Arial, sans-serif",
    },
    headingM: {
      fontSize: "40px",
      fontWeight: 600,
      letterSpacing: " -2%",
      color: theme.colors.textDark,
      fontFamily: "Inter, Arial, sans-serif",
    },
    headingS: {
      fontSize: "36px",
      fontWeight: 600,
      letterSpacing: " -2%",
      color: theme.colors.textDark,
      fontFamily: "Inter, Arial, sans-serif",
    },
    headingXS: {
      fontSize: "32px",
      fontWeight: 600,
      letterSpacing: " -2%",
      color: theme.colors.textDark,
      fontFamily: "Inter, Arial, sans-serif",
    },
    headingXXS: {
      fontSize: "24px",
      fontWeight: 600,
      letterSpacing: " -2%",
      color: theme.colors.textDark,
      fontFamily: "Inter, Arial, sans-serif",
    },
    headingXXXS: {
      fontSize: "24px",
      fontWeight: 600,
      letterSpacing: " -0.48px",
      color: theme.colors.textDark,
      fontFamily: "Inter, Arial, sans-serif",
    },
    bodyL: {
      fontSize: "18px",
      fontWeight: 400,
      color: theme.colors.textSecondary,
      fontFamily: "Inter, Arial, sans-serif",
    },
    bodyM: {
      fontSize: "16px",
      fontWeight: 400,
      color: theme.colors.textSecondary,
      fontFamily: "Inter, Arial, sans-serif",
    },
    bodyS: {
      fontSize: "14px",
      fontWeight: 400,
      color: theme.colors.textSecondary,
      fontFamily: "Inter, Arial, sans-serif",
    },
    bodyXS: {
      fontSize: "12px",
      fontWeight: 400,
      color: theme.colors.textSecondary,
      fontFamily: "Inter, Arial, sans-serif",
    },
  };
}
