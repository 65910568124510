import React, { useState } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { validateForm } from "../../../utils/Validation";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSignUpData } from "../../../redux/slices/auth/signupSlice";
import TextFieldComponent from "../../../components/TextFieldComponent";
import Logo from "../../../assets/images/logo/logo.svg";

const SignUp = () => {
  const { signUpData } = useSelector((state) => state.root.signup);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    first_name: signUpData ? signUpData?.first_name : " ",
    last_name: signUpData ? signUpData?.last_name : " ",
    company_name: signUpData ? signUpData?.company_name : " ",
    phone_number: signUpData ? signUpData?.phone_number : " ",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone_number" && !/^\d*$/.test(value)) {
      return; // Prevent non-numeric input
    }
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "", // Clear the error for the current field
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateForm(formData);
    if (!isValid) {
      setErrors(errors);
    } else {
      dispatch(setSignUpData(formData));
      navigate("/signupsubmit");
    }
  };

  return (
    <>
      <div className="authPage SignUp">
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6} md={6} style={{ position: "relative" }}>
            <div className="authLeft">
              <div className="authLogo">
                <img src={Logo} alt="Logo" />
              </div>
              <div className="authBottomContent"></div>
              <div className="authTopContent">
                <div className="inner">
                  <Typography
                    variant="headingS"
                    className="text-secondary"
                    component="h2"
                    sx={{ letterSpacing: "normal", mb: 0 }}
                  >
                    Never miss a single profit
                  </Typography>
                  <Typography
                    color="white"
                    component="p"
                    sx={{ fontSize: "18px", mb: 0 }}
                  >
                    We file and monitor claims on your behalf while you focus on
                    the business
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={8} spacing={2}>
                <div
                  className="authForm"
                  style={{
                    display: "flex",
                    padding: "0px 16px",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Box
                    className="logo"
                    sx={{ display: { sm: "block", md: "none" } }}
                  >
                    <img src={Logo} alt="Logo" />
                  </Box>
                  <div>
                    <Typography
                      variant="headingXS"
                      component="h3"
                      sx={{ letterSpacing: "normal" }}
                    >
                      Create an account
                    </Typography>
                    <Typography
                      variant="bodyM"
                      component="p"
                      sx={{ letterSpacing: "normal", mb: 2 }}
                    >
                      Fill in your user information
                    </Typography>
                  </div>
                  <TextFieldComponent
                    id="first_name"
                    name="first_name"
                    label="First name"
                    value={formData?.first_name}
                    onChange={handleInputChange}
                    error={errors?.first_name}
                  />
                  <TextFieldComponent
                    id="last_name"
                    name="last_name"
                    label="Last name"
                    value={formData?.last_name}
                    onChange={handleInputChange}
                    error={errors?.last_name}
                  />
                  <TextFieldComponent
                    id="company_name"
                    name="company_name"
                    label="Company name"
                    value={formData?.company_name}
                    onChange={handleInputChange}
                    error={errors?.company_name}
                  />
                  <TextFieldComponent
                    id="phone_number"
                    name="phone_number"
                    label="Phone number"
                    value={formData?.phone_number}
                    onChange={handleInputChange}
                    error={errors?.phone_number}
                  />
                  <Button
                    sx={{ mt: 2 }}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Next
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default SignUp;
