import React from "react";
import { TextField, InputLabel } from "@mui/material";

const TextFieldComponent = ({
  id,
  name,
  type,
  value,
  onChange,
  error,
  label,
  ...props
}) => {
  return (
    <div className="formGroup">
      <InputLabel
        shrink
        htmlFor={id}
        // sx={{ textAlign: "left", fontWeight: 500 }}
        error={!!error}
        // className='font-tiny secondary-text-color'
      >
        {error ? (
          <span>{error}</span>
        ) : (
          <>
            {label}
            {/* <span style={{ color: "red" }}>*</span> */}
          </>
        )}
      </InputLabel>
      <TextField
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        type={type || "text"}
        fullWidth
        {...props}
      />
    </div>
  );
};

export default TextFieldComponent;
