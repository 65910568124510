import { createAsyncThunk } from "@reduxjs/toolkit";
import { authApiWithToken } from "../../../utils/authApi";
import { setLoading } from "../../slices/dataLoadingSlice";
import { setStoreList } from "../../slices/storeListSlice";

// get store list
export const getStoreList = createAsyncThunk(
  "get/storeList",
  async (request, { dispatch }) => {
    try {
      const response = await authApiWithToken.get(`/account/store-list/`);
      dispatch(setLoading(false));
      if (response.data.success === true) {
        dispatch(setStoreList(response?.data.data));
      }
    } catch (error) {
      console.log("error: ", error);
      dispatch(setLoading(false));
    }
  },
);
