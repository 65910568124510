import { createAsyncThunk } from "@reduxjs/toolkit";
import { authApiWithToken } from "../../../utils/authApi";
import { setLoading } from "../../slices/dataLoadingSlice";
import { setStoreData } from "../../slices/storedetails/storedetailsSlice";
import { SuccessToast } from "../../../utils/Toaster";

// get store data
export const getStoreData = createAsyncThunk(
  "get/storedata",
  async (request, { dispatch }) => {
    try {
      const page_size = request?.perPageNum ? request?.perPageNum : 10;
      const page_num = request?.pageNum ? request?.pageNum : 1;
      const response = await authApiWithToken.get(
        `/account/user/store/list/?&page=${page_num}&page_size=${page_size}`,
      );
      dispatch(setLoading(false));

      if (response.data.success === true) {
        dispatch(
          setStoreData({
            tableData: response?.data.data,
            pagination: response?.data?.pagination,
          }),
        );
      }
    } catch (error) {
      console.log("error: ", error);
      dispatch(setLoading(false));
    }
  },
);

//update store data
export const updateStoreData = createAsyncThunk(
  "get/storedata",
  async (request, { dispatch }) => {
    try {
      const id = request?.id;
      const response = await authApiWithToken.patch(
        `/account/user/store/${id}/`,
        { ...request.formdata },
      );
      dispatch(setLoading(false));

      if (response.data.success === true) {
        SuccessToast(response.data.message);
        dispatch(getStoreData());
      }
    } catch (error) {
      console.log("error: ", error);
      dispatch(setLoading(false));
    }
  },
);

//add store data
export const addStoreData = createAsyncThunk(
  "add/storedata",
  async (request, { dispatch }) => {
    try {
      const response = await authApiWithToken.post(
        `/account/user/accounts/add/`,
        { ...request.formdata },
      );
      dispatch(setLoading(false));

      if (response.data.success === true) {
        console.log(response.data);
        SuccessToast(response.data.message);
        dispatch(getStoreData());
      }
    } catch (error) {
      console.log("error: ", error);
      dispatch(setLoading(false));
    }
  },
);

//add default card data
export const addDefaultCardData = createAsyncThunk(
  "add/storedata",
  async (request, { dispatch }) => {
    try {
      const response = await authApiWithToken.post(`/api/set-default-card/`, {
        ...request,
      });
      dispatch(setLoading(false));

      if (response.data.success === true) {
        SuccessToast(response.data.message);
        dispatch(getStoreData());
      }
    } catch (error) {
      console.log("error: ", error);
      dispatch(setLoading(false));
    }
  },
);
