import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  storeList: [],
};

const storeListSlice = createSlice({
  name: "storeListSlice",
  initialState,
  reducers: {
    setStoreList: (state, action) => {
      return {
        ...state,
        storeList: action.payload,
      };
    },
  },
});

export const { setStoreList } = storeListSlice.actions;

export default storeListSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectStoreList = (state) => state.root.storeListState.storeList;
