import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  storeData: {},
};

const storedetailsSlice = createSlice({
  name: "storedetailsSlice",
  initialState,
  reducers: {
    setStoreData: (state, action) => {
      return {
        ...state,
        storeData: action.payload,
      };
    },
  },
});

export const { setStoreData } = storedetailsSlice.actions;

export default storedetailsSlice.reducer;
